@import '../../palette';

.alert-settings-container {
    background: $light-grey;
    border: 1px solid $grey;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1em;
    margin-top: 1em;
    position: relative;
}

.alert-settings-toggle-button {
    border: none;
    background: none;
    display: block;
    text-align: left;
    line-height: 32pt;
}

.alert-settings-info-button {
    line-height: 32pt;
    text-align: center;
    font-size: 24pt;

    &:hover {
        cursor: pointer;
    }
}

.alert-settings-toggle-arrow {
    font-size: 18pt;
    display: inline-block;
    width: 2rem;
}

.alert-list-settings-heading {
    font-size: 18pt;
    font-weight: 200;
}

.alert-filter-type-checkbox-container {
    line-height: 0.2rem;
    margin-bottom: 0;
    max-height: 2rem;
    font-size: 24pt;
}

.alert-filter-type-checkbox {
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
}

.alert-filter-form-separator {
    border-bottom: 1px dashed #333;
}

.alert-settings-form-vspacer {
    height: 2rem;
    width: 100%;
}

.alert-list-settings-toggles {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 0rem;
    text-align: left;

    li {
        display: block;
        line-height: 2em;
        word-wrap: nowrap;
        padding: 0.2em;
        padding-left: 0.5rem;

        &:hover {
            cursor: pointer;
            background: #fafafa;
        }
    }

    .colour-box {
        width: 1.5em;
        height: 1.5em;
        display: inline-block;
        border: 2px solid $dark-background;
        vertical-align: middle;
        text-align: center;
        font-weight: 600;
        line-height: 1.2em;
        color: rgba(0, 0, 0, 0.5);

        &.far-threshold-box {
            color: black;
        }
    }

    .key-label {
        margin-left: 0.5em;
        display: inline-block;
        color: $dark-background;
        vertical-align: middle;
    }
}