@import '../../palette.scss';

.contact-title {
	display: block;
	margin-top: 2rem;
	font-size: 40pt;
}

.imageCredit{
	float:right;
}

.contact-name {
	line-height: 28px;
	margin-right: 1em;
	display: inline-block;
}

.button-container {
	padding: 1em;
	padding-left: 0;
	display: inline-block;
	line-height: 28px;
	vertical-align:middle !important;
	min-height: 48px;

	div, button {
		margin-right: 0.5em;
		display: inline-block;
	}

	iframe { 
		vertical-align: middle;
	}

	button {
		height: 28px;
		line-height: 28px;
		padding: 0px 10px;
	}
}

.contact-rule {
	border: 0px;
	border-top: 1px solid rgba(255, 255, 255, 0.4);
	margin: 2rem 0;
}