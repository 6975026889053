.obs-status-title-text  {
    font-size: 24pt;
    font-weight: bold;
    color: #ffffff;
    text-align: left;
    display: inline-block;
    padding-top: 30px;
    margin-right: 10px;
}

.obs-status-container  {
    background-color: #260055;
    position: relative;
    text-align: left;
    padding: 0px 20px;
    line-height: 3.5rem;
}

.obs-status-header-icons {
    display: inline-block;

    .obs-status-header-icon {
        font-size: 18pt;
        margin-right: 10px;
    }
}

.obs-status-row-container  {
    padding: 0;
    margin: 0;
    position: relative;
}

.obs-status-loading-container {
    opacity: 0.5;
}

.obs-status-name-text  {
    color: #FFFFFF;
    font-size: 16pt;
    display: inline;
    margin: 0;
    padding: 0;
}

.obs-status-circle-container  {
    line-height: 14pt;
    vertical-align: middle;
    position: absolute;
    right: 0;
    top: 25%;
    height: 50%;
}

.obs-status-circle  {
    padding-left: 5px; 
    padding-right: 10px;
    height: 100%;
    border-radius: 20px;
    border:3px solid #FFFFFF;
    line-height: 10pt;
}

.obs-status-red-circle {
    background-color: #9c0000,
}

.obs-status-amber-circle  {
    background-color: #ffc107,
}

.obs-status-green-cricle {
    background-color: green,
}

.obs-status-circle-icon  {
    color: #FFFFFF;
    font-size: 17pt;
    display: inline-block;
    vertical-align: middle;
}

.obs-status-duration-text  {
    color: #FFFFFF;
    font-size: 14pt;
    display: inline;
    vertical-align: middle;
}

.obs-status-maintenance-duration-text {
    color: #212529;
}

.obs-status-last-updated-container  {
    padding: 0px;
}

.obs-status-last-updated-text {
    color: #bbbbbb;
    font-size: 12pt;
}

.obs-status-loading-indicator {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}
    
.obs-status-props-row-tooltip-icon {
    color: #dedede; 
    font-size: 24pt;
    padding-top: 15px;
    padding-bottom: 15px;
}
