@import '../palette.scss';
@import "../scss/bootstrap/scss/functions";
@import "../scss/bootstrap/scss/variables";
@import "../scss/bootstrap/scss/mixins/_breakpoints";

.threeDimage-container {
    border: 1px solid #9A9A9A;
    min-height: 300px;
    height: 100%;
    width: 100%;
    background: rgb(94,108,112);
    background: radial-gradient(circle, rgba(94,108,112,1) 0%, rgba(2,0,36,1) 79%); 
}

@include media-breakpoint-up(md) {
    .threeDimage-container {
        min-height: 400px; // I have adjusted this to closely match the skymap size, but fee
    }
}

@include media-breakpoint-up(lg) {
    .threeDimage-container {
        min-height: 660px; // I have adjusted this to closely match the skymap size, but fee
    }
}

.threeD-viewer-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:hover {
        cursor: grab;
    }

    &:active {
        cursor: grabbing;
    }
}

.skymap-model-loader {
    position: relative;
    text-align: center;
    line-height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 75pt;

        // Text properties
        font-size: 18pt;
        font-weight: bold;

        .skymap-model-loader-text, .skymap-model-loader-spinner {
            display: block;
            width: 100%;
        }
    }

    &.loading {
        display: block;
    }
}

.skymap-model-size-warning {
    position: relative;
    text-align: center;
    line-height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    div {
        position: absolute;
        left: 20%;
        top: 50%;
        transform: translate(-10%, -50%);
        line-height: 20pt;

        // Text properties
        font-size: 18pt;
        font-weight: bold;

        .skymap-model-size-warning-text {
            display: block;
            width: 100%;
        }

        .skymap-model-size-warning-subtitle {
            font-size: 12pt;
            display: block;
            width: 100%;
        }

        .skymap-model-size-warning-button {

        }
    }

    &.loading {
        display: block;
    }
}