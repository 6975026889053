@import '../../palette';
@import '../../scss/bootstrap/scss/bootstrap.scss';

.App {
}
.image-title-active {
  background-color:red !important; 
  display:inline-bloc
}
.image-title-inactive {
  background-color:red !important; 
  display:inline-bloc
}
h3 {
  display: inline-block;
}

.App-link {
  color: #61dafb;
}

.App-main-content {
  padding: 1em;
  max-width: 1400px;
  margin: 0 auto;
}

#alert-name-title {
  text-align: left;
  border-bottom: 1px dashed $dark-background;
  margin-bottom: 0.1em;
}

.alert-subtitle {
	margin-bottom: 0.2em;
	font-style: italic;
	font-size: 12pt;
  text-align: left;
  font-weight: 600;
}

.alert-subtitle-header {
  margin-bottom: 0.2em;
  font-size: 12pt;
  text-align: left;
  font-weight: 600;
}

.alert-status-subtitle {
  margin-bottom: 2rem;
  font-style: italic;
  font-size: 12pt;
  display: block;

  &.retracted {
    color: red;
  }

  &.candidate {
    color: green; 
  }

  &.published {
    color: mediumblue; 
  }
}


@include media-breakpoint-up(sm) {
  .alert-status-subtitle {
    text-align: right;
    float: right;
    margin-bottom: 2em;
  }

  .alert-subtitle {
    margin-bottom: 2em;
  }
}


.Container-row-spacer, .Container-row-spacer-md {
  height: 1rem;
  width: 100%;
  clear: both;
  display: block;
}

@include media-breakpoint-up(md) {
  .Container-row-spacer-md {
    display: none;
  }
}

#instrument-list {
	padding: 0;
  margin: 0;
  list-style: none;

  li {
    display: inline;

    &:after {
      content: ", ";
      width: 10pt;
      display: inline-block;
    }
    &:last-child:after {
      content: "";
    }
  }

  a {
    color: inherit;

    &:hover {
      color: #FFF;
      text-decoration: underline;
    }
  }
}

// Scoopers backwards burst hack

$container-border-radius: 0.5em;

.tooltip-container {
  max-width: 50%;
  vertical-align: top;
}

.content-Container{ 
  font-size: 12pt;
  color: #FFF;
  padding: 0px;
  text-align: left;
  background-color: $dark-background;
  border-radius: $container-border-radius;
  height: 100%;
  position: relative;

  a {
        color: #0bf;
        
        &:hover {
            color: #006bff;
        }
    }

  .Container-title {
    padding: 0.7em 1em 0 1em;
    font-size: 24pt;
  }

  .image-container-tab {
    font-size: 12pt;
    display: inline-block;
    height: 30px;
    border: none;
    border-top: 1px solid #9A9A9A;
    border-bottom: 1px solid #9A9A9A;
  }

  .image-container-tab:first-of-type {
    border-top-left-radius: 0.2em;
    border-bottom-left-radius: 0.2em;
    border: 1px solid #9A9A9A;
  }

  .image-container-tab:last-of-type {
    border-top-right-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
    border: 1px solid #9A9A9A;
  }
  
  .image-tab-container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: -15px;
    z-index: 1000;
  }

  .activeButton {
    background:$light-blue;
  }

  .modal-open-icon {
    &:hover {
      cursor: pointer;
    }
  }

  ul {
    list-style: none;
    display: inline-block;
    margin: 0px;
    padding: 1em 1em 1em 1em;
    text-align: left;

    .burst-list-item {
      color: #dedede;
      text-decoration: none;
      display: block;
      padding: 0.5em 1em 0.5em 1em;
      height: 100%;
    }

    .burst-list-item-label {
      vertical-align: top;
    }

    .burst-list-item-property {
      color: $light-blue;
      font-weight: 600;
      letter-spacing: 0.75pt;
    }

    .burst-list-item-value {
      color: $grey;
      vertical-align: top;
    }
  }
}