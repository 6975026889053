@import '../../palette';

.load-more-container {
    text-align: center;
}

.load-more-button {
    margin: 0 auto;
    text-align: center;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 1em;
    padding: 0.2em;
    padding-left: 1em;
    padding-right: 1em;
    font-size: 12pt;

    &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.1);
    }
}