@import '../../palette.scss';

.about-title {
	display: block;
	margin-top: 2rem;
	font-size: 40pt;
}

.imageCredit{
	float:right;
	
}

.title{ 
	fontSize:34pt;
}