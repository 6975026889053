@import '../palette';
@import "../scss/bootstrap/scss/functions";
@import "../scss/bootstrap/scss/variables";
@import "../scss/bootstrap/scss/mixins/_breakpoints";

@import '../config';

.App-header {
  background-color: $dark-background;
  min-height: $header-height;
  line-height: $header-height;
  display: block;
  flex-direction: column;
  align-items: right;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 5;
}

.menu-container {
	font-size: 12pt;
	color: #FFF;
	height: 100%;

	padding: 0px;
	margin: 0px;
	display: block;
	position: relative;

	.menu-logo {
		padding: 0 1em 0 0.5em;
		display: inline-block;
		//background-image: linear-gradient(to bottom, $chirp-light-purple, $chirp-dark-purple);
		background-color: #13141f;
		color: #FFF;
		text-align: center;
		font-weight: bolder;
		position: relative;
		overflow: visible;
		height: 100%;

		p {
			font-size: 14pt;
			display: inline;
			margin-left: 4.1rem;
		}

		#header-logo-icon {
			height: 4rem;
			display: static;
			position: absolute;
			z-index: 2;
		}

		// The old version of the logo that had ripples on the right hand side.
		/*
		&:after {
			content: "";
			display: static;
			background: url('/lsc_waves.png');
			width: 4rem;
			height: 31px;
			background-size: 100%;
			background-repeat: no-repeat;
			position: absolute;
			bottom: -0.25rem;
			right: -0.25rem;
			pointer-events: none;
			z-index: 2;
		}
		*/

		&:hover, &:visited:hover {
			background: $menu-gradient;
			color: $dark-blue;
			text-decoration: none;
		}
	}
}


#menu-checkbox {
	display: none;
}

#menu-checkbox-label {
	display: none;
	margin: 0px;
	padding: 0 2em 1px 2em;
	float: right;

	&:hover, &:visited:hover {
		background: $menu-gradient;
		color: $dark-blue;
		cursor: pointer;
	}
}

.menu-list-container {
	display: inline-block;
	height: 100%;
	text-align: right;
}

ul.menu {
	list-style: none;
	display: inline-block;
	margin: 0px;
	padding: 0;
	text-align: left;
	height: 100%;
	
	a.list-item {

		color: #dedede;
		text-decoration: none;
		display: inline-block;
		padding: 1pt 1rem 0 1rem;

		&:hover, &:visited:hover {
			background: $menu-gradient;
			color: $dark-blue;
		}

		&:visited {
			color: #9a9a9a;
		}
	}
}

#menu-network-status-icon {
	float: right;
	vertical-align: bottom;

	.circle {
		display: inline-block;
		height: 1em;
		width:	1em;
		border-radius: 100%;
		border: 2px solid white;
		margin-left: 0.3em;

		&:last-of-type {
			margin-right: 1rem;
		}
	}
}

.obs-status-mobile-menu-container {
	display: none;
	z-index: 1;
}

#menu-network-status-icon {
	padding: 1px 0.3em 1px 10px;

	&:hover {
		background: $menu-gradient;
		cursor: pointer;

		.menu-network-status-text {
			color: black;
		}

		.obs-status-circle  {
			border-color: black;
		}
	}
}

.menu-status-circle-container {
	display: inline-block;
	margin-right: 0.5rem;

	.obs-status-circle {
		border-radius: 0.2em;
		border-width: 2px;
		text-align: center;
		font-size: 10pt;
		padding: 0.1em 0.2em 0.2em 0.2em;
		font-weight: bolder;
	}

	.obs-status-amber-circle {
		color: black;
	}
}

.menu-obs-status-container {
	position: absolute;
	right: 0;
	display: none;
	z-index: 90;
}

#obs-status-menu-checkbox {
	display: none;
}

#obs-status-menu-checkbox:checked ~ #menu-network-status-icon {
	background: $menu-gradient;

	.menu-network-status-text {
		color: black;
	}

	.obs-status-circle  {
		border-color: black;
	}
}

#obs-status-menu-checkbox:checked ~ .menu-obs-status-container {
	display: block;
}

@include media-breakpoint-down(md) {
	.menu-container {
		.menu-logo {
			display: inline-block;
		}

		#menu-network-status-icon {
			display: none;
		}
	}

	.obs-status-mobile-menu-container {
		display: none;
	}

	.menu-list-container {
		display: block;
	}

	#menu-checkbox-label {
		display: inline-block;
	}

	ul.menu {
		display: none;
	}

	#menu-checkbox:checked ~ .obs-status-mobile-menu-container {
		display: block;
	}

	#menu-checkbox:checked ~ ul.menu {
		display: block;
	}

	ul.menu {
		padding: 0px;
		a.list-item {
			display: block;
			width: 100%;
		}
	}

	#obs-status-menu-checkbox:checked ~ .menu-obs-status-container {
		display: none;
	}
}