@import '../palette.scss';

.image-container {

	border: 1px solid $grey;
	padding: 0.5em;
	background: #FFF;
	height: 100%;

	img {
		display: block;
		width: 100%;
	}
}