@import "../../scss/bootstrap/scss/functions";
@import "../../scss/bootstrap/scss/variables";
@import "../../scss/bootstrap/scss/mixins/_breakpoints";

.home-content {
    background-image: url('/NSRender.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.home-title {
    padding: 1rem 0 2rem 0;
    color: #FFF;
    font-size: 40pt;
}

.home-masthead-container {
    margin-top: 10rem;
    background: rgba(0, 0, 0, 0.5);
    width: 50%;
    margin-left: 10%;
    color: #FFF;
    padding-bottom: 2rem;

    a {
        color: #0bf;

        &:hover {
            color: #006bff;
        }
    }
}

.home-masthead-button {
    margin: 1rem;
    margin-left: 0;
}

@include media-breakpoint-down(sm) {
    .home-masthead-container {
        width: 100%;
        margin: 0px;
        margin-top: 10%;
        height: 100%;
        padding: 5rem 0; 
    }
}

.right{
    text-align:right;
}

.storeButton{
    position:relative; 
    width:250px;
    margin-left:-15px;
    max-width:230px;
    align:left;
    display:inline-block;
}
.appleLogo {
    position:relative;
    display:inline-block;
    width:200px;
    max-width:200px;
    align:left;
}