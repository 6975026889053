@import '../palette';
@import "../scss/bootstrap/scss/functions";
@import "../scss/bootstrap/scss/variables";
@import "../scss/bootstrap/scss/mixins/_breakpoints";

$page-segment-border-radius: 0.5em;
$image-overlay-gradient: linear-gradient(to right, $dark-background 10%, rgba(0, 0, 0, 0.0) 50%);

.page-segment {
	font-size:12pt;
	color:#FFF;
	text-align:left;
	background-color:$dark-background;
    border-radius: $page-segment-border-radius;
    /*position: relative;*/

    a {
        color: #0bf;
        
        &:hover {
            color: #006bff;
        }
    }
    
    .page-segment-title {
        margin: 1rem;
        position: relative;
        z-index: 10;
    }

    .page-segment-contents {
        padding:1rem;

        &.with-image {
            width: 66%;
            position: relative;
            z-index: 10;
        }
    }

    .page-segment-image-container {
        display: inline-block;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 50%;
        overflow: hidden;
        z-index: 5;
        border-top-right-radius: $page-segment-border-radius;
        border-bottom-right-radius: $page-segment-border-radius;
    }

    .page-segment-image-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: $image-overlay-gradient;
    }

    .page-segment-image {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: right;
    }
}

// Let's style these when they get to smaller values

@include media-breakpoint-down(md) {
    .page-segment {
        .page-segment-contents {
            &.with-image {
                width: 100%;
                position: relative;
                z-index: 10;
            }
        }

        .page-segment-image-container {
            left: 0;
            border-radius: $page-segment-border-radius;
        }

        .page-segment-image-overlay {
            background: rgba(0, 0, 0, 0.5);
        }
    }
}